export enum EnvironmentsEnum {
  Environment = 'environment',
  Development = 'development',
  Testing = 'testing',
  Staging = 'staging',
  Production = 'production'
}

export interface IAuthorizationBearerParams {
  grant_type: string;
  client_id: string;
  client_secret: string;
}

interface IEnvironment {
  version: string;
  environment: EnvironmentsEnum;
  serverStartTime: number;
  production: boolean;
  port: number;
  apiUrl: string;
  brxmBaseUrl: string;
  cmsBaseUrl: string;
  aladdinOnDemandUrl: string;
  authorizationBearerParams: IAuthorizationBearerParams;
}

export default IEnvironment;
