import IEnvironment, { EnvironmentsEnum } from '@environments/environment.interface';

const baseCmsUrl = 'https://dev-cmsapi.mohawkind.com/site/ac';

export const environment: IEnvironment = {
  version: '0.0.0',
  environment: EnvironmentsEnum.Development,
  serverStartTime: new Date().getTime(),
  production: false,
  port: 0,
  apiUrl: '',
  brxmBaseUrl: baseCmsUrl,
  cmsBaseUrl: `${baseCmsUrl}/resourceapi`,
  aladdinOnDemandUrl: 'https://api.cjjsup9vs1-mohawkcar1-s1-public.model-t.cc.commerce.ondemand.com',
  authorizationBearerParams: {
    grant_type: 'client_credentials',
    client_id: 'aladdin',
    client_secret: 'Mohawk@123'
  }
};
